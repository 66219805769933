import React from 'react';

const RepaymentCharge = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>Early repayment charge (ERC) is a penalty from the lender if you pay off your mortgage early or exceed your overpayment allowance</li>
              <li>ERC is usually unavoidable when you sell your property within the initial deal period, or are remortgaging for equity release</li>
              <li>Overpayment limits are usually 10% of the mortgage balance each year before incurring an ERC</li>
              <li>You can usually remortgage six months before your existing deal comes to an end</li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              What are early repayment charges and how to avoid them?<br/><br/>
              Early repayment charges are a penalty by the mortgage provider in any event, you are going to pay off the mortgage either in full within a fixed period or you exceed your overpayment allowance set by the bank.<br/><br/>
              The mortgage provider would not change a fixed percentage depending on when you repay the mortgage and the details of the penalty will be noted within the original mortgage offer.<br /><br/>
              There are circumstances in which you have no option but to pay the early payment charges and this can involve selling the property or attempting to remortgage to raise finance within the penalty period. Ways to avoid incurring an early payment charge is you try to stay within your overpayment limit.<br /><br/>
              Typically banks normally charge between 5% and 10% allowing you to overpay on your mortgage. For example, if the mortgage is £100,000 then the maximum you could pay off in either a lump sum or overpayment within the calendar year would be between £5,000 and £10,000.<br /><br/>
              The amount will depend on the bank's mortgage offer terms and conditions. When remortgaging you can normally secure your rate on the new mortgage product within three months of the current mortgage product's end date, sometimes six months.<br /><br/>
              So that when the current mortgage does finally come to an end, the new remortgage and the product secured will go live therefore avoiding the early repayment charge.<br /><br/>
            </p>
          </div>
    </div>
  );
};

export default RepaymentCharge;
